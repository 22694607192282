<template>
  <div style="width: 100%; height: 100%" class="fadeIn">
    <van-nav-bar
      title="原因设置"
      left-text="返回"
      right-text="添加"
      left-arrow
      @click-left="$router.go(-1)"
      @click-right="show = true"
    />
    <el-table :data="causeList" height="80%">
      <el-table-column
        label="原因说明"
        prop="causeName"
        width="210"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            round
            size="mini"
            @click="editName(scope.row)"
            plain
            >修改</el-button
          >
          <el-button
            type="danger"
            round
            size="mini"
            @click="deleteClassify(scope.row.causeId)"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="position: fixed; bottom: 0; right: 0; width: 100%">
      <van-pagination
        v-model="causePage.current"
        :total-items="causePage.total"
        :items-per-page="causePage.size"
        :show-page-size="5"
        force-ellipses
        @change="currentChange"
      />
    </div>
    <van-popup
      v-model="show"
      round
      position="top"
      :style="{ height: '30%', background: '#f7f8fa' }"
      closeable
      @close="close"
    >
      <h4 style="margin: 20px">
        {{ formCause.causeId ? "修改原因" : "新增原因" }}
      </h4>
      <van-divider
        :style="{
          borderColor: '#b1b1b1',
          padding: '0 16px',
          margin: '0 2px',
        }"
      />
      <van-form style="width: 80%; margin: 0 auto">
        <van-field
          clearable
          v-model="formCause.causeName"
          label="原因名称"
          placeholder="请输入原因"
          maxlength="15"
        />
        <div style="margin: 30px 0 0 70%; width: 30%">
          <van-button round block type="info" size="mini" @click="onsubmit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
let that;
export default {
  data() {
    return {
      causeList: [],
      show: false,
      formCause: {
        causeName: "",
        projectGroupId: this.$store.state.projectGroupId * 1,
      },
      causePage: {
        projectGroupId: this.$store.state.projectGroupId * 1,
        causeName: "",
        size: 10,
        current: 1,
        total: 0,
      },
    };
  },
  created() {
    that = this;
    this.getCauseList();
  },
  methods: {
    deleteClassify(id) {
      this.$dialog
        .confirm({
          title: "删除操作",
          message: "此操作会永久删除文件，继续吗？",
        })
        .then(() => {
          this.$api.setCause.delCause(id).then((res) => {
            if (res.code == 200) {
              this.$toast.success("删除成功!");
              this.getCauseList();
            }
          });
        })
        .catch(() => {
          this.$toast.fail("取消删除");
        });
    },
    editName(data) {
      this.show = true;
      this.formCause = data;
    },
    close() {
      this.formCause = {
        causeName: "",
        projectGroupId: this.$store.state.projectGroupId * 1,
      };
      this.show = false;
    },
    async onsubmit() {
      try {
        let res = {};
        if (this.formCause.causeId) {
          let data = {
            causeId: this.formCause.causeId,
            causeName: this.formCause.causeName,
            projectGroupId: this.formCause.projectGroupId,
          };
          res = await that.$api.setCause.upDateCause(data);
        } else {
          res = await that.$api.setCause.addCause(that.formCause);
        }
        if (res.code == 200) {
          this.close();
          this.getCauseList();
          this.$toast.success(res.msg);
        }
      } catch (e) {
        this.$toast.fail(e.msg);
      }
    },
    currentChange(val) {
      that.causePage.current = val;
      that.getCauseList();
    },
    getCauseList() {
      that.$api.setCause.getCause(that.causePage).then((res) => {
        if (res.code == 200) {
          that.causePage.total = res.data.total;
          that.causeList = res.data.records;
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
